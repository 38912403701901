













import { Component, Vue, Watch } from 'vue-property-decorator';
import { useWebSocket } from '@vueuse/core';
import { Store } from 'le5le-store';
// 导入topology-vue组件
import topology from 'topology-vue';
// 需要导入topology-vue.css
import 'topology-vue/topology-vue.css';

// import axios from 'axios';
import axios from '@/http';

import {
  // defalutMaterials,
  defalutMaterialTabs,
  iconMenus,
  userMenus,
} from './data';

Vue.use(topology);

declare const window: any;

@Component({})
export default class Home extends Vue {
  topologyConfigs = {
    license: {
      key: '20330',
      value: '248rkrjrkrjrFrjrjrFrjrkBarirer2rkrHr2rkrirGrfrjrjBo',
      version: '16BfBJB6'
    },
    logo: null,
    menus: iconMenus,
    loginUrl: null,
    signupUrl: null,
    userMenus: null,
    materialTabs: defalutMaterialTabs,
    monaco: 'https://cdn.staticfile.org/monaco-editor/0.19.3/min',
    dataOptionsFn: (pen: Node, key: string, value: string) => {
      const keys = [{ value: 'aaa', label: '1' }];
      const values = [{ value: '000', label: '1' }];
      return {
        keys,
        // value: true, // 可配置，部门情况可能出现类型报错
        values,
      };
    },
    fileUpload: (blob: Blob, shared: boolean, fileName: string) => {
      return this.upload(blob, shared, fileName);
    },
  };

  user: any = {
    username: ''
  };

  materials: any = {
    topology: [],
    system: [],
    user: [],
    images: [],
    uploadUrl: '/topologyapi/image',
    uploadHeaders: {
      Authorization: 'your token',
    },
    uploadParams: {
      randomName: 1,
      public: true,
    },
  };
  // 当前打开的文件夹
  folder = '';
  // 传值过来的数据库
  db = '';
  // 传值过来的账号名
  username = ''
  // 传值过来的项目代号
  project = '';
  state = {
    server: 'ws://www.juxin-cloud.com/overall-socket',
    sendValue: ''
  };

  data: any = {
    websocket: '',
    mqttUrl: '',
    component: !!this.$route.query.component,
  };

  created() {
    const _that = this
    // 返回之后获取数据
    _that.project = String(_that.$route.query.projectCode)
    _that.db = String(_that.$route.query.db)
    // iframe接收传值
    window.addEventListener(
      'message',
      function(e: any) {
        // console.log('方法里执行了', e.data, e.data.type);
        let data = e.data;
        if (data.type === 'webpackOk' || data.type === 'webpackInvalid' || data.vscodeSetImmediateId) {
          return;
        } else {
          _that.user.username = data.username;
          _that.db = data.db
          _that.username = data.username
          // 防止空的情况进入所有图纸
          data.code = data.code === undefined ? 'error' : data.code
          _that.project = data.code
          _that.$http.post(`link/folder/update`, {
            // 此处的数组对应项目文件夹
            topology: [data.code]
          }).then(() => {
            _that.getMaterials()
          });
        }
      },
      false
    );
    const data = (window as any).topologyData;
    // 预览页返回，存在缓存数据
    if (data) {
      this.data = { data: Object.assign({}, data) };
      setTimeout(() => {
        (window as any).topologyData = null;
      }, 200);
    } else {
    }
    this.getMaterials()
    this.init();
  }

  group(arr: any[], key: string) {
    var map = {},
      dest = [];
    for (var i = 0; i < arr.length; i++) {
      var ai = arr[i];
      if (!map[ai[key]]) {
        dest.push({
          key: ai[key],
          data: [ai],
        });
        map[ai[key]] = ai;
      } else {
        for (var j = 0; j < dest.length; j++) {
          var dj = dest[j];
          if (dj.key == ai[key]) {
            dj.data.push(ai);
            break;
          }
        }
      }
    }
    return dest;
  }
  registerBuiness() {
    if (window.registerTools) {
      window.registerTools();
    }
    if (window.topologyTools) {
      let list = this.group(window.topologyTools, 'class');
      this.materials.system.push(
        ...list.map((el) => {
          return {
            name: el.key,
            expand: false,
            show: true,
            list: el.data,
          };
        })
      );
    }
    if (window.registerIot) {
      window.registerIot(this.topologyConfigs.license);
        this.materials.system.push(
      ...[
        {
          iconUrl: '/font/font_2073009_u56zfo0voi/iconfont.css',
          show: true,
          list: [] // 此处留空数组就好，会自动填充
        },
        {
          iconUrl: '/font/font_2395018_pl6jy69tbjr/iconfont.css',
          show: true,
          list: [] // 此处留空数组就好，会自动填充
        }
      ]
    );}
  }

  @Watch('$route')
  routerChanged() {
    this.init();
  }

  async init() {
    if (this.$route.query.id) {
      let ret: any = await axios.get('/topologyapi/topology/' + this.$route.query.id, {
        params: {
          version: this.$route.query.version,
          view: 1,
        },
      });
      if (ret.error) {
        return;
      }

      if (!ret.pens) {
        const data = ret.data;
        delete ret.data;
        ret = Object.assign(ret, data);
      }
      this.data = ret;
    } else {
      this.data = (window as any).topologyData || {
        component: !!this.$route.query.component,
        folder: this.$route.query.folder,
      };

      setTimeout(() => {
        (window as any).topologyData = null;
      }, 200);
    }
  }
  async getImageMeterials() {
    if (!this.user) {
      return [];
    }
    const result: any = await axios.get('/topologyapi/user/component/images', {
      params: { pageIndex: 1, pageCount: 1000 },
    });

    return result.data.list || [];
  }

  MaterialsGroup(data: any[], folders?: any[]) {
    const obj: any = {};
    if (!folders) {
      if (data.length === 0) {
        // 长度为0
        obj['Uncategorized(未分类)'] = [];
      } else {
        data.forEach((item: { folder: string }) => {
          if (!item.folder) {
            item.folder = 'Uncategorized(未分类)';
          }

          if (!obj[item.folder]) {
            obj[item.folder] = [item];
          } else {
            obj[item.folder].push(item);
          }
        });
      }
    } else {
      folders.forEach((name: string) => {
        obj[name] = [];
      });
      obj['Uncategorized(未分类)'] = [];
      data.forEach((item: { folder: string }) => {
        if (!obj[item.folder]) {
          obj['Uncategorized(未分类)'].push(item);
        } else {
          obj[item.folder].push(item);
        }
      });
    }

    const list: any[] = [];
    // tslint:disable-next-line:forin
    for (const key in obj) {
      list.push({ name: key, list: obj[key] });
    }

    return list;
  }

  async getMaterials() {
    let folder: any = await axios.get('/topologytp/user/folder');
    
    if (folder.data.error) {
      folder = {};
    } else {
      folder = folder.data;
    }

    const tools: any = await axios.get('/topologyapi/tools');
    const group: any = {};
    tools.data.forEach((item: any) => {
      if (!group[item.subClassName]) {
        group[item.subClassName] = {
          name: item.subClassName,
          show: true,
          expand: true,
          list: [item],
        };
      } else {
        group[item.subClassName].list.push(item);
      }
    });

    this.materials.system = [];
    for (const key in group) {
      this.materials.system.push(group[key]);
    }

    // 注册图形库
    this.registerBuiness();

    const images = await this.getImageMeterials();
    const user = this.MaterialsGroup(images, folder.user);
    const topology = this.MaterialsGroup([], folder.topology);

    this.materials.topology = topology;
    this.materials.user = user;
    this.materials.images = images;

    const imagesRes: any[] = await axios.get('/images');
    this.materials.system.push(
      ...imagesRes.data.map(item => {
        return {
          name: item.name, // 目录名
          list: item.list.map((ele: any) => {
            return {
              name: ele.name, // hover item 名
              image: ele.url // 预览图以及拖出来的效果图
            };
          }),
          show: true,
          expand: false
        };
      })
    );

    this.materials = Object.assign({}, this.materials);
  }

  async openFolder(params: { folder: string; component: boolean }) {
    const folder =
      params.folder === 'Uncategorized(未分类)' ? '' : params.folder;
      
    // 获取当前文件夹名称
    this.folder = params.folder

    const rep: any = await axios.get('/topologyapi/user/topologies', {
      params: {
        folder,
        component: params.component,
        pageIndex: 1,
        pageCount: 100,
      },
    });
    // 对数据进行调整
    if (params.component) {
      // 组件
      this.setFolderData(this.materials.user, params.folder, rep.data);
      this.materials.user = [].concat(this.materials.user);
    } else {
      // 图纸
      this.setFolderData(this.materials.topology, params.folder, rep.data);
      this.materials.topology = [].concat(this.materials.topology);
    }
    this.materials = Object.assign({}, this.materials);
  }

  /**
   * 为对应文件夹设置数据
   * @param folders 这里指 materials 中的 user 或 topology
   * @param folderName 文件名，展开的文件名
   * @param rep 请求到的结果数据
   */
  private setFolderData(folders: any, folderName: string, rep: any) {
    // 组件
    const folder = folders?.find((item) => {
      return item.name === folderName;
    });
    if (folder) {
      folder.loading = false;
      !Array.isArray(folder.list) && (folder.list = []);
      // 已经是数组了
      // 未分类
      if (folderName === 'Uncategorized(未分类)') {
        // 对查到的结果进行筛选，''  和 未分类都属于该文件夹
        rep.list?.forEach((item) => {
          if (!item.folder || item.folder === 'Uncategorized(未分类)')
            folder.list.push(item);
        });
        return;
      }
      // 其他类型文件夹
      rep.list && folder.list.push(...rep.list);
    }
  }

  mounted() {
    const json = sessionStorage.getItem('topologyData');
    if (!this.$route.query.id && json) {
      this.data = JSON.parse(json);
      setTimeout(() => {
        // 读到后清除对应 session
        sessionStorage.removeItem('topologyData');
      }, 200);
    }
    window.topology.data.socketEvent = true;
    window.topology.on('websocket', (e: any) => {
      // 接收和发送的消息都是 json 格式的，消息格式：{ topic: '--', info: {} }，具体见后端 websocket 文件
      const recData = JSON.parse(e);
      console.log('recData', recData, 'collection', this.db, this.project);
      
      switch (recData.topic) {
        case 'connection': {
          if (recData.info.connection) {
            const alarmSubscribe = {
              topic: 'systemDataSubscribe',
              info: { collection: `${this.db}${this.project}` }
            };
            window.topology.socket.socket.send(JSON.stringify(alarmSubscribe));
            console.log('websocket连接成功');
          }
          break;
        }
        case 'systemDataSubscribe': {
          console.log('刷新数据：', JSON.parse(e).info.data.devices[0].deviceData);
          const res = JSON.parse(e).info.data.devices[0].deviceData;
          for (let item in res) {
            res[item] = res[item] === 0 ? '0' : res[item]
            window.topology.setValue(item, { text: res[item] });
          }
          // window.topology.setValue('SLP_0OVS019_S0', { text: res.SLP_0OVS019_S0 })
          break;
        }
        // case 'SLPAlarm': {
        //   console.log('接收到告警信息：', recData);
        //   break;
        // }
      }
    });
  }

  onEvent(e: { name: string; params: any }) {
    switch (e.name) {
      case 'add-topology-folder':
        // this.addTopologyFolder(e.params);
        this.getMaterials();
        alert('禁止添加，请到项目配置添加');
        break;
      case 'rename-topology-folder':
        // this.renameTopologyFolder(e.params);
        this.getMaterials();
        alert('禁止重命名，请到项目配置修改');
        break;
      case 'remove-topology-folder':
        // this.removeTopologyFolder(e.params);
        this.getMaterials();
        alert('禁止删除，请到项目配置删除');
        break;

      case 'add-user-folder':
        // this.addUserFolder(e.params);
        this.getMaterials();
        alert('禁止添加，请到项目配置添加');
        break;
      case 'rename-user-folder':
        // this.renameUserFolder(e.params);
        this.getMaterials();
        alert('禁止重命名，请到项目配置修改');
        break;
      case 'remove-user-folder':
        // this.removeUserFolder(e.params);
        this.getMaterials();
        alert('禁止删除，请到项目配置删除');
        break;
      case 'addImageUrl':
        this.addComponentImage(e.params);
        break;
      case 'deleteImage':
        this.deleteComponentImage(e.params);
        break;
      case 'logout':
        // 退出登录
        this.user = null;
        // Do sth.
        break;

      case 't-topology-folder-open': // 打开文件夹
        this.openFolder(e.params);
        break;

      case 'openMaterialGroup':
        // 展开/折叠图标工具栏分组
        // Do sth.
        break;

      case 'addMaterial':
        // 添加“我的组件”
        // Do sth. For example:
        this.$router.push({
          path: '/',
          query: { folder: e.params, component: '1', projectCode: this.project, db: this.db, r: Date.now() + '' },
        });
        break;

      case 'editMaterial':
        // 编辑“我的组件”
        // Do sth. For example:
        this.$router.push({
          path: '/',
          query: {
            id: e.params.id,
            folder: e.params,
            component: '1',
            projectCode: this.project, db: this.db, r: Date.now() + '',
          },
        });
        break;

      case 'newFile':
        this.$router.push({
          path: '/',
          query: { folder: e.params, projectCode: this.project, db: this.db, r: Date.now() + '' },
        });
        break;
      case 'save':
        this.save();
        break;

      case 'view':
        this.$router.push({
          path: '/',
          query: { id: e.params.id, projectCode: this.project, db: this.db, r: Date.now() + '' },
        });
        break;
      case 'preview':
        // 点击工具栏“预览”
        // 保存当前编辑数据，方便预览时直接打开
        (window as any).topologyData = (window as any).topology.data;
        this.$router.push({
          path: '/preview',
          query: { id: this.$route.query.id, projectCode: this.project, db: this.db, r: Date.now() + '' },
        });
        break;
      case 'remove-user-component': // 删除用户组件
      case 'remove-topology': // 删除图纸
        axios.delete(`/topologyapi/user/topology/${e.params.id}`);
        break;
      case 'remove-user-image': // 删除图片
        axios.post('/topologyapi/user/component/image/delete', {
          folder: e.params.folder,
          image: e.params.image,
        });
        break;
      case 'show-home': //保存预览url
        if (this.folder === '') {
          alert('设置失败，未选择文件夹');
        } else if (this.$route.query.component) {
          alert('不能设置组件作为首页展示');
        } else {
          if (this.$route.query.id) {
            this.$http.post(`link/preview/add`, {
              username: this.username,
              code: this.folder,
              url: this.$route.query.id,
              db: this.db
            });
            alert('设置成功');
          } else {
            alert('请选择图纸')
          }
        }
        break;
      // ...
      // ...
    }
  }

  async addTopologyFolder(name: string) {
    let folder: any = await axios.post('/topologyapi/user/folder', {
      type: 'topology',
      name,
    });
    if (folder.data.error) {
      alert('新增文件夹失败：' + folder.data.error);
    }
  }

  async renameTopologyFolder(params: any) {
    params.type = 'topology';
    let folder: any = await axios.post('/topologyapi/user/folder', params);
    if (folder.data.error) {
      alert('修改文件夹失败：' + folder.data.error);
    }
  }

  async removeTopologyFolder(params: any) {
    params.type = 'topology';
    let folder: any = await axios.post('/topologyapi/user/folder/delete', params);
    if (folder.data.error) {
      alert('删除文件夹失败：' + folder.data.error);
    }
  }

  async addUserFolder(name: string) {
    let folder: any = await axios.post('/topologyapi/user/folder', {
      type: 'user',
      name,
    });
    if (folder.data.error) {
      alert('新增文件夹失败：' + folder.data.error);
    }
  }

  async renameUserFolder(params: any) {
    params.type = 'user';
    let folder: any = await axios.post('/topologyapi/user/folder', params);
    if (folder.data.error) {
      alert('修改文件夹失败：' + folder.data.error);
    }
  }

  async removeUserFolder(params: any) {
    params.type = 'user';
    let folder: any = await axios.post('/topologyapi/user/folder/delete', params);
    if (folder.data.error) {
      alert('删除文件夹失败：' + folder.data.error);
    }
  }

  save(tip = true) {
    (window as any).topology.toImage(10, async (blob: any) => {
      if (this.data.id) {
        if (!(await this.delImage(this.data.image))) {
          // return;
        }
      }

      const file = await this.upload(blob, true);
      if (!file) {
        return;
      }

      this.data = Object.assign({}, (window as any).topology.pureData());
      if (this.data.component) {
        this.data.componentData = (window as any).topology.toComponent();
      }

      this.data.image = file.url;

      for (const item of this.data.pens) {
        delete item.elementId;
        delete item.elementLoaded;
        delete item.elementRendered;
        // 删除 options
        if (item.data && item.data instanceof Array) {
          for (const rank of item.data) {
            delete rank.options;
          }
        }
      }

      let ret: any;
      if (!this.data.name) {
        this.data.name = `topology.${new Date().toLocaleString()}`;
      }
      if (this.data.id) {
        this.data.mqttOptions = null
        
        ret = await axios.put('/topologyapi/user/topology', this.data);
      } else {
        ret = await axios.post('/topologyapi/user/topology', this.data);
      }

      if (ret.data.error) {
        return null;
      }

      if (!this.data.id || this.$route.query.version) {
        this.data.id = ret.data.id;
        this.$router.push({
          path: '/',
          query: { id: this.data.id },
        });
      } else {
      }

      tip && alert('保存成功！');

      this.getMaterials();
    });
  }

  async upload(blob: Blob, shared = false, filename = '/topology/thumb.png') {
    const form = new FormData();
    form.append('path', filename);
    form.append('randomName', '1');
    form.append('public', shared + '');
    form.append('file', blob);
    const ret: any = await axios.post('/topologyapi/image', form);
    if (ret.data.error) {
      return null;
    }

    return ret.data;
  }

  async delImage(image: string) {
    const ret: any = await axios.delete('/topologyapi' + image);
    if (ret.data.error) {
      return false;
    }

    return true;
  }

  async addComponentImage(params: any) {
    const ret: any = await axios.post('/topologyapi/user/component/image', params);
    if (ret.error) {
      return false;
    }

    this.getMaterials();
  }

  async deleteComponentImage(params: any) {
    const ret: any = await axios.post(
      '/topologyapi/user/component/image/delete',
      params
    );
    if (ret.error) {
      return false;
    }

    this.getMaterials();
  }
}
