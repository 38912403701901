import axios from 'axios';
import { Cookie, Store } from 'le5le-store';

// axios 配置
axios.defaults.timeout = 500000;
axios.defaults.withCredentials = false;

// http request 拦截器
axios.interceptors.request.use(
  (config: any) => {
    config.headers.Authorization = Cookie.get(process.env.VUE_APP_TOKEN) || '';
    // let userInfo = Store.get('userInfo')
    // if(userInfo){
        config.headers.CustomAuth = JSON.stringify({
          // 不以username以及userId区分系统，只以folder区分系统，所以暂时写死userId和username。
          // userId:userInfo.userId,
          // userName:userInfo.username,
          // jd:userInfo.jd,
          userId: 1,
          userName: 'admin',
        })
    // }
    return config;
  },
  (err: any) => Promise.reject(err)
);

// http response 拦截器
axios.interceptors.response.use(
  (response: any) => {
    if (response.data.error) {
      Store.set('api error', response.data.error);
    }
    return response;
  },
  (error: any) => {
    Store.set('http error', error.response);
    return Promise.reject(error.response.data);
  }
);

export default axios;
