import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios'

// const localhost = '127.0.0.1'
  //设置api主接口
// Vue.prototype.$http = axios.create({
//   baseURL: `http://${localhost}:3001/link`
// })
Vue.prototype.$http = axios.create()

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
